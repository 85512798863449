.root {
}

.inputError {
  border: var(--borderErrorField);
}

.subLabel {
  font-size: 12px;
  font-style: normal;
  padding-top: 0;
  line-height: 16px;
  font-weight: 100;
}
