@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price,
.favoriteListing {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.favoriteListing {
  @media (--viewportMedium) {
    margin: 0;
    position: absolute;
    right: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;
  position: relative;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h4 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.priceContainer {
  /* Layout */
  flex: 1;
  padding: 5px 12px;

  & .basePrice {
    margin-right: 15px;
  }

  & .perUnit {
    margin-top: 3px;
    margin-left: 5px;
  }

  & .priceNote {
    font-size: 16px;
    line-height: 1.2;
    margin-top: 5px;
  }
}

.priceNote {
  color: var(--colorGrey700);
  font-size: 16px;
  line-height: 1.2;
  margin-top: 12px;
  width: 100%;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
  }
}

.basePrice,
.extraGuestPrice {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
}

.basePrice {
  margin-right: 20px;

  & .priceNote {
    white-space: nowrap;
  }
}

.extraGuestPrice {
  & .priceValue {
    color: var(--colorGrey300);
    position: relative;

    &::before {
      content: '+';
      line-height: 24px;
      position: absolute;
      left: -20px;

      @media (--viewportMedium) {
        line-height: 36px;
      }
    }
  }

  & .priceNote {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 4px;

    @media (--viewportMedium) {
      margin-top: 6px;
    }
  }
}

.perUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey300);
  margin-top: 4px;
  margin-left: 6px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
