.dimensions {
  margin-bottom: 24px;
  @media (--viewportLarge) {
    margin-bottom: 32px;
  }
}

.dimensionContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.dimensionInput {
  display: flex;
  max-width: 150px;
  margin-right: 20px;
  background-color: #f3f5f5;
  border-bottom-style: none;
  padding: 10px;
  padding-left: 15px;
  border-radius: 5px;
  align-items: center;
}

.dimensionContainer input {
  border-bottom-style: none;
}

.dimensionContainer > span,
.dimensionInput > span {
  font-size: 16px;
  font-weight: 600;
  color: var(--matterColorAnti);
}
